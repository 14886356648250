import styled from "styled-components";
import LogoBlack from "../assets/HW_logo_black.png";
// import LogoWhite from "../assets/HW_logo_white.png";

const Headers = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/assets/healingworks_deck.png");
    background-size: cover;
    background-position: center;
    transform: rotate(0deg);
    z-index: -1;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const Logoimg = styled.img`
  width: 100vh;
  object-fit: contain;
  transform: rotate(270deg);
  position: relative;
  left: 0;
  float: left;

  position: absolute;
  left: 0;
  transform: translate(-37%, 0%) rotate(90deg);
  height: 100vh;
`;

const TextContainer = styled.div`
  flex: 1;
  text-align: center;
  padding: 30px;

  h2 {
    color: black;
  }

  p {
    font-size: 1em;
    color: black;
  }
`;

const Header: React.FC = () => {
  return (
    <Headers>
      <LogoContainer>
        <Logoimg src={LogoBlack} alt="logo" />
      </LogoContainer>
      <TextContainer>
        <h2>FEEL THE HEALING.</h2>
        <p>Transforming Lives through Healing Art.</p>
        <p>
          Our artwork offers beauty and tranquility, providing healing through
          art.
        </p>
      </TextContainer>
    </Headers>
  );
};

export default Header;
