import React from "react";
import styled from "styled-components";
import Logowhite from "../assets/HW_logo_white.png";

const FooterWrapper = styled.div`
  background-color: black;
  color: white;
  padding: 20px;

  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const LogoFooter = styled.div`
  text-align: center;
  margin-bottom: 20px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const FooterInner = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 40px; /* Contact와 General 사이를 띄우기 위한 간격 추가 */

  @media (max-width: 768px) {
    flex-direction: column; /* 모바일에서 세로 정렬 */
  }
`;

const Column = styled.div`
  flex: 1;
  min-width: 180px;
  margin: 10px 0;
  font-size: 12px;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;

      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  label {
    color: #7a7a7a;
  }

  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    min-width: 150px;
    width: 100%;
    margin-bottom: 20px;

    font-size: 10px;
  }
`;

const ContactBlock = styled.div`
  margin-top: 10px;
  .title {
    font-weight: bold;
  }

  .value {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    .title {
      margin-right: 10px;
    }
  }

  @media (max-width: 768px) {
  }
`;

const CopyrightRow = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 10px;

  a {
    color: white;
    text-decoration: none;
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper id="navi-footer-wrapper">
      <LogoFooter className="logo-footer">
        <img src={Logowhite} alt="Logo" className="max" />
      </LogoFooter>

      <Container className="container-fluid">
        <FooterInner>
          <ContactBlock>
            <div className="navi-row">
              <Row className="d-none d-md-flex">
                <Column>
                  <label className="title">Contact</label>
                  <div className="value">
                    <a href="mailto:contact@healingworks.art">
                      contact@healingworks.art
                    </a>
                  </div>
                  <div className="value">
                    서울특별시 강남구 테헤란로70길 12,
                    <br />
                    402-J102
                  </div>
                </Column>
                <Column>
                  <label className="title">General</label>
                  <div className="value">(주) 힐링웍스</div>
                  <div className="value">CEO : 김 재 형</div>
                  <div className="value">Tax ID : 357-81-03431</div>
                </Column>
              </Row>
            </div>
          </ContactBlock>
        </FooterInner>

        <CopyrightRow className="copyright-row">
          <div>
            <a href="/terms-conditions">Terms & Conditions</a>|
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
          <div>&copy; 2024 Healingworks Inc. All rights reserved.</div>
        </CopyrightRow>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
